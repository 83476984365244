import axios from 'axios';
import moment from 'moment-timezone';
import Helpers from '../../../utils/Helpers';

/**
 * @description API to create a brand campaign
 * @async
 * @function PostCreateCampaignAPI
 * @returns Promise
**/


const PostCreateCampaignAPI = async (body) => {

  try {

    const apiUrl = process.env.REACT_APP_API_URL + "/campaign";
    let cardNumberStripped = body.billingDetails.cardNumber.replace(/ /g, '');

    var today = new Date();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    var sdate = new Date(body.campaignDetails.startDate);
    var edate = new Date(body.campaignDetails.endDate);

    let start_date = moment(sdate.getFullYear() + '/' + (sdate.getMonth() + 1) + '/' + sdate.getDate() + ' ' + time).utc().format('YYYY/MM/DD HH:mm:ss');
    let end_date = moment(edate.getFullYear() + '/' + (edate.getMonth() + 1) + '/' + edate.getDate() + ' ' + time).utc().format('YYYY/MM/DD HH:mm:ss');

    let hashtagFormatting = "";
    hashtagFormatting = body.campaignDetails.brandMoreHashtags.split(',')
    hashtagFormatting = hashtagFormatting.filter(Boolean);
    hashtagFormatting = hashtagFormatting.map(i => (i.charAt(0) !== '#' ? '#' + i : i));
    hashtagFormatting = hashtagFormatting.toString();

    const stripeCharges = Helpers.calculateStripeCharge(!body.billingDetails.couponDetails ? body.campaignDetails.payoutAmount : body.billingDetails.couponDetails.campaign_payable_amount)

    const formatAddress = (addressString) => {

      let newAddress = addressString.split(',');
      return newAddress[0];

    }
    const formData = {
      "budget_total": body.campaignDetails.payoutAmount,
      "stripe_charge": stripeCharges,
      "card_exp_month": body.billingDetails.paymentMode === "previousCards" ? null : parseInt(body.billingDetails.expiryMonth),
      "card_exp_year": body.billingDetails.paymentMode === "previousCards" ? null : parseInt("20" + body.billingDetails.expiryYear),
      "card_number": body.billingDetails.paymentMode === "previousCards" ? null : cardNumberStripped,
      "customerid": body.billingDetails.paymentMode === "previousCards" ? body.billingDetails.selectedPreviousCard.customer_id : null,
      "cvc": body.billingDetails.paymentMode === "previousCards" ? null : body.billingDetails.cvv,
      "description": null,
      "end_date": (body.campaignDetails.expirable) ? end_date.toString() : null,
      "image_name": body.imageName,
      "name": body.campaignDetails.campaignName,
      "posts_total": body.campaignDetails.noOfPosts,
      "price_per_post": body.campaignDetails.perPost,
      "start_date": start_date.toString(),
      "type_id": body.campaignDetails.campaignType,
      "location": body.campaignDetails.campaignType !== "302" ? body.campaignDetails.location : null,
      "preferred_network": body.campaignDetails.network,
      "time_period": body.campaignDetails.limitPosting,
      "post_limit": body.campaignDetails.limitPosting !== "NoLimit" ? body.campaignDetails.limitPostingNumber : null,
      "hash_tag": body.campaignDetails.brandMoreHashtags !== "" ? hashtagFormatting : null,
      "campaign_preference_request": {
        "by_appointment_only": body.campaignDetails.appointmentOnly,
        "is_hidden_campaign": body.campaignDetails.hiddenCampaign,
        "is_post_photo_on_event_day": body.campaignDetails.postEventDay,
        "is_purchase_required": body.campaignDetails.purchaseRequired,
        "minimum_followers": body.campaignDetails.minFollowers,
        "post_stay_days": body.campaignDetails.postDuration,
        "is_recieved_discount": body.campaignDetails.is_recieved_discount,
        "recieved_discount": body.campaignDetails.recieved_discount,
        "recieved_discount_coupon_code": body.campaignDetails.recieved_discount_coupon_code,
        "is_must_be21_or_older": body.campaignDetails.isMustBe21OrOlder,
        "purchase_value": '@' + body.campaignDetails.purchaseValue,
        "is_preloaded_image": body.campaignDetails.ispreloadedimage,
        "preloaded_image_path": body.preloadedImagepath
      },
      "campaign_multimedia_request": [
        body.campaignDetails.isTiktokEnable && { "handle": body.campaignDetails.brandTIKTOKHandle, "network": "TIKTOK" },
        body.campaignDetails.isTwitterEnable && { "handle": body.campaignDetails.brandTwitterHandle, "network": "TWITTER" },
        { "handle": body.campaignDetails.brandInstaHandle, "network": "INSTAGRAM" }
      ].filter(Boolean)
    }
  
    if (body.campaignDetails.campaignType === "302") {
      if (body.campaignDetails.storeSelection === "Few") {
        let locations = [];
        locations = body.campaignDetails.addressData.map((data) => ({
          address1: formatAddress(data.addressReal),
          city: data.city,
          state: data.state,
          latitude: data.latitude,
          longitude: data.longitude,
          status_id: 0,
          zip_code: data.zipcode,
          zip_code_plus4: data.zipcodePlus4 ? data.zipcodePlus4 : null
        }))
        formData.locations = locations;
        formData.is_upload_csv = false;
      } else {
        formData.is_upload_csv = true;
      }

    }

    if (body.billingDetails.couponDetails !== null) {
      formData.coupon_id = body.billingDetails.couponDetails.coupon_id
    }

    const config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access-token'),
      }
    }
    const response = await axios.post(apiUrl, formData, config);
    return response.data;

  } catch (e) {
    return e;
  }

}

export default PostCreateCampaignAPI;
